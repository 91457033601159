import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'; 
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding:theme.spacing(5)
  }
}));

const summary = () => (
      <Grid item >
        <Typography variant="body1" style={{marginBottom:8}}>
        Applied physicist, software developer, product director, entrepreneur. Seeking a role as a product
manager at a growing and socially conscious company. Fluent in Chinese. Technical proficiencies:
C++, Python, CUDA, OpenCV, React, D3, Firebase, Node.js, AWS, Google Cloud, Javascript, C#,
Unity, Swift, JIRA, Continuous Integration
        </Typography>
        <Divider/>
      </Grid>
)

const palisade = () => (
  <Grid item >
    <Typography variant="h6" style={{marginBottom:8}}>
    Palisade Labs
    </Typography>
    <Typography variant="body1" style={{marginBottom:8}}>
    Co-founded a data + video intelligence platform empowering more efficient mobility 
    <br/><br/>
<li>Built founding client base for IoT consultancy spanning software and hardware </li>
<li>Awarded opportunity with the Transit Tech Lab for a 1-year pilot with the MTA </li>
<li>Delivered actionable insights to improve transit efficiency with our machine learning and computer vision intelligence platform </li>
    </Typography>
    
  </Grid>
)

const smoothtechnology = () => (
  <Grid item >
    <Typography variant="h6" style={{marginBottom:8}}>
    Smooth Technology
    </Typography>
    <Typography variant="body1" style={{marginBottom:8}}>
    Full stack engineering support for high exposure Smooth Tech projects. 
    We make really bright costumes for pop stars like Katy Perry, Taylor Swift, 
    and Nicki Minaj. 
    We launch products with Google Creative Lab Lab (https://papersignals.withgoogle.com). 
    We make custom art around NYC, usually around the theme of lighting & robotics. 
    I specialize in conversational UIs, computer vision, and real-time data management    </Typography>
    
  </Grid>
)

const heep = () => (
  <Grid item >
    <Typography variant="h6" style={{marginBottom:8}}>
    Heep 
    </Typography>
    <Typography variant="body1" style={{marginBottom:8}}>
    Co-founded a startup that built an edge computing platform providing intelligent, connected infrastructure
    <br/><br/>
<li>Low-footprint firmware to run on low-cost, resource-constrained microcontrollers </li>
<li>Distributed, peer-to-peer communication with no hubs required</li>
<li>Local-first, cloud-optional</li>
<li>Designed for any physical layer (WiFi, Ethernet, BLE, etc.)</li>
<li>Infinitely scalable to enable vast networks</li>
<li>Extract and process data at the edge to mitigate latency and bandwidth issues</li>
    </Typography>
    
  </Grid>
)

const nanotronics = () => (
  <Grid item >
    <Typography variant="h6" style={{marginBottom:8}}>
    Nanotronics Imaging 
    </Typography>
    <Typography variant="body1" style={{marginBottom:8}}>
    Built robot microscope for computer chip manufacturers around the world
    <br/><br/>
<li>Guided product roadmap towards larger market segments, completing and launching two new products that enabled us to sell to device manufacturers in addition to substrate manufacturers</li>
<li>Led an agile transformation that unlocked continuous deployment and reduced bugs</li>
<li>Expanded sales internationally, entering markets in Taiwan, Japan, and South Korea</li>
<li>Managed a growing engineering team spread out across three different states.</li>
<li>Developed computer vision algorithms to identify manufacturing defects on semiconductor substrates. </li>
<li>Supported sales and customer success in the field globally, with a focus on China and Taiwan. </li>
<li>Instrumental in landing Nanotronics' first 1M+ account.</li>
    </Typography>
    
  </Grid>
)

const nasa = () => (
  <Grid item >
    <Typography variant="h6" style={{marginBottom:8}}>
    NASA 
    </Typography>
    <Typography variant="body1" style={{marginBottom:8}}>
    Interned at Marshal Space Flight Center through their NASA Academy program. I highly recommend to anyone looking to get a high level overview of the landscape at NASA. 
    <br/><br/>
<li>	Studied cryogenic and high temperature performance of Direct Laser Metal Sintered (DMLS) Inconel-718 engine components </li>
<li>Designed two new cryostats for Marshall's Cryogenic testing facility </li>
<li>Designed a Hot Isostatic Pressing (HIP!) glove box to test depleted uranium CERMET powder for thermonuclear propulsion applications</li>
    </Typography>
    
  </Grid>
)

function App() {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} sm={11}>
          <Grid container spacing={4} justify="left" alignItems="center" style={{marginBottom:12}}>
            <Grid item>
            <Avatar 
              alt="Jacob Keith" 
              src="https://firebasestorage.googleapis.com/v0/b/jacob-keith.appspot.com/o/JK_Avatar.jpg?alt=media&token=0aefff5b-3bd5-4747-b6c5-009ff85d2440" 
              style={{
                width:80,
                height: 80
              }} />
            </Grid>
            <Grid>
              <Typography variant="h4" gutterBottom>
                Jacob Keith
              </Typography>
              
            </Grid>
          </Grid>
          <Divider/>
          
          <Grid container spacing={3} justify="left" style={{marginTop:8}}>
            {summary()}
            {palisade()}
            {smoothtechnology()}
            {heep()}
            {nanotronics()}
            {nasa()}
          </Grid>
        </Grid>
      </Grid>
      
    </div>
  );
}

export default App;
